import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Table, Textarea, Input } from "@mantine/core";
import { useAxiosInstance } from "../axios/useAxios";
import { stateContext } from "../context/FunctionContext";
import FormModal from "../components/modals/FormModal";
import { Link } from "react-router-dom";
import DisplayFiles from "../components/modals/DisplayFiles";
import jwtDecode from "jwt-decode";
import ComponentPagination from "../components/Pagination";

const TablesStudents = () => {
  // State
  const axios = useAxiosInstance();
  const { URL_API, token, currentPage, setCurrentPage } =
    useContext(stateContext);
  const [id, setId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [grandFatherName, setGrandFatherName] = useState("");
  const [showOpenModal, setShowOpenModal] = useState(false);
  const [data, setData] = useState(null);
  const [filesStudent, setFilesStudent] = useState(null);
  const [allStudents, setAllStudents] = useState(null);
  const [allData, setAllData] = useState(null);

  // note
  const [modalNote, setModalNote] = useState(false);
  const [note, setNote] = useState("");

  const handleClick = async (virtualId) => {
    try {
      const res = await axios.get(URL_API + `student`, {
        params: {
          virtualId: virtualId,
        },
      });

      if (res.status === 404) {
        return alert("لايوجد متدرب مطابق للرقم التعريفي");
      }

      if (res.status !== 200) {
        return alert("شيءً ما خاطئ");
      }

      setShowOpenModal(true);
      setData(res.data[0]);
    } catch (error) {
      alert(error.message);
      console.log(error.message);
    }
  };

  const getStudents = async () => {
    const userId = jwtDecode(token);
    const res = await axios.get("student", {
      params: {
        pageInfo: true,
        page: currentPage,
        incubationTrainerId: userId.data.trainerId,
        virtualId: id.length > 0 ? id : undefined,
        fundingAnswerNotNull: true,
        phone: phoneNumber.length > 0 ? phoneNumber : undefined,
        firstName: firstName.length > 0 ? firstName : undefined,
        fatherName: fatherName.length > 0 ? fatherName : undefined,
        grandFatherName:
          grandFatherName.length > 0 ? grandFatherName : undefined,
      },
    });
    setAllStudents(res.data.list);
    setAllData(res.data);
  };

  useEffect(() => {
    getStudents();
  }, [currentPage]);

  const getFilesStudent = async (id) => {
    try {
      const res = await axios.get(URL_API + `incubator/student/${id}`);

      if (res.status !== 200) {
        return alert("شيءً ما خاطئ");
      }

      setFilesStudent(res.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleSendNote = async () => {
    try {
      const res = await axios.post("incubator/note", {
        content: note,
        studentId: modalNote.id,
        type: "student",
      });

      if (res.status !== 200) {
        return alert("خطأ ما");
      }

      alert("تم انشاء الملاحظة");
      window.location.reload();
    } catch (error) {
      console.log(error.message);
    }
  };

  const rows = allStudents?.map((student, i) => (
    <tr key={student.id}>
      <td>
        {student.firstName +
          " " +
          student.fatherName +
          " " +
          student.grandFatherName}
      </td>
      <td>{student.phone}</td>
      <td>{student.virtualId}</td>

      <td style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Link state={student.id} to={"/messenger/" + student.id}>
          <Button cursor={"pointer"} variant="riyada">
            مراسلة
          </Button>
        </Link>

        <Button
          onClick={() => getFilesStudent(student.id)}
          cursor={"pointer"}
          variant="riyada"
        >
          التنفيذ
        </Button>

        {student.incubatorFormId && (
          <Button
            cursor={"pointer"}
            variant="riyada"
            onClick={() => handleClick(student.virtualId)}
          >
            معاينة العقد
          </Button>
        )}

        <Button variant="riyada" onClick={() => setModalNote(student)} my="md">
          اكنب ملاحظتك
        </Button>
      </td>
    </tr>
  ));

  return (
    <div className="">
      <div className=" w-full grid grid-cols-5 gap-2">
        <input
          className="col-span-5 md:col-span-1 px-4 py-2 ring ring-gray-100 outline-none rounded-sm duration-150 focus:ring-[#31C48D]"
          onChange={(e) => setFirstName(e.target.value)}
          placeholder="اسم الطالب"
          variant="default"
        />
        <input
          onChange={(e) => setFatherName(e.target.value)}
          className="col-span-5 md:col-span-1 px-4 py-2 ring ring-gray-100 outline-none rounded-sm duration-150 focus:ring-[#31C48D]"
          placeholder="اسم اب الطالب"
        />
        <input
          onChange={(e) => setGrandFatherName(e.target.value)}
          className="col-span-5 md:col-span-1 px-4 py-2 ring ring-gray-100 outline-none rounded-sm duration-150 focus:ring-[#31C48D]"
          placeholder="اسم جد الطالب"
        />
        <input
          onChange={(e) => setId(e.target.value)}
          className="col-span-5 md:col-span-1 px-4 py-2 ring ring-gray-100 outline-none rounded-sm duration-150 focus:ring-[#31C48D]"
          placeholder="رمز الطالب التعريفي"
        />
        <input
          onChange={(e) => setPhoneNumber(e.target.value)}
          className="col-span-5 md:col-span-1 px-4 py-2 ring ring-gray-100 outline-none rounded-sm duration-150 focus:ring-[#31C48D]"
          placeholder="رقم الهاتف"
        />
        <Button
          size={"md"}
          mt={"xl"}
          variant="riyada"
          fullWidth
          onClick={getStudents}
          className="col-span-5"
        >
          بحث
        </Button>
        {/* <input
          placeholder="ادخل الرقم التعريفي للمتدرب"
          onChange={(e) => setId(e.target.value)}
          autoFocus
        /> */}
      </div>

      {allData?.totalPages > 0 && (
        <ComponentPagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={allData?.totalPages}
        />
      )}

      <Modal
        dir="rtl"
        opened={modalNote}
        onClose={() => {
          setModalNote(null);
        }}
        centered
        closeButtonLabel="lg"
        size={"lg"}
        withOverlay
      >
        <Textarea
          size="md"
          radius="xs"
          label="الملاحظات"
          withAsterisk
          placeholder="اكتب ملاحظتك"
          onChange={(e) => setNote(e.target.value)}
          mt={"xl"}
        />

        <Button
          disabled={note === ""}
          size={"md"}
          mt={"xl"}
          variant="riyada"
          fullWidth
          onClick={() => handleSendNote()}
        >
          حفظ المعلومات
        </Button>
      </Modal>

      {filesStudent && (
        <Modal
          dir="rtl"
          padding={50}
          size={800}
          opened={filesStudent}
          onClose={() => setFilesStudent(null)}
          centered
        >
          <DisplayFiles data={filesStudent} />
        </Modal>
      )}

      <Modal
        dir="rtl"
        padding={50}
        size={800}
        opened={showOpenModal}
        onClose={() => setShowOpenModal(false)}
        centered
      >
        <FormModal
          data={data?.incubatorForm}
          virtualId={data?.virtualId}
          approvedIncubatorForm={data?.isIncubatorApprovedTheIncubatorForm}
          incubatorContractId={data?.incubatorContractId}
        />
      </Modal>

      {allStudents?.length > 0 && (
        <Table striped highlightOnHover>
          <thead>
            <tr>
              <th> الاسم </th>
              <th>رقم الهاتف</th>
              <th>الرقم التعريفي</th>
              <th>التغييرات</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      )}

      {!allStudents && <p className="text-xl">لايوجد متدربين</p>}
    </div>
  );
};

export default TablesStudents;
